import React from 'react'
import '../privacyPolicy/PrivacyPolicy.css'

const RefundPolicy = () => {
    return (
        <>
            <div className="container-div-1">
                <div className="containar-box-1" style={{width:"70%"}}>

                 <div className="terms-container">
      <div className="section">
        <h1>Refund Policy:
</h1>
  <p><strong>If Project Canceled by the Customer:</strong></p>

      <p><strong>1. Advance Payment:</strong> Any advance payment made by the Client to the Company for the project is non-refundable 
         under any circumstances. The advance is considered a commitment towards the development work and will not be returned 
         if the Client decides to cancel or terminate the project.
      </p>

      <p><strong>2. Cancellation and Payment Policy According to Project Type:</strong></p>

      <p><strong>• Custom Project:</strong> If the Client decides to cancel a custom project after the advance has been paid, the Client remains 
         obligated to pay a portion of the project amount proportional to the work completed. The Company reserves the right to claim payment 
         for the portion of the project that has been completed, even if the project is canceled before full completion.
      </p>

      <p><strong>• Ready-to-Use Project:</strong> For ready-to-use projects, cancellation is not valid. The Company reserves the right to claim 
         full payment for ready-to-use projects, and no refund will be provided if the Client chooses to discontinue or not proceed with the project.
      </p>

      <p><strong>• Hybrid Project (Custom + Ready-to-Use):</strong> In the case of a Hybrid Project, where the total project amount is ₹2 lakh, 
         and 80% of the project is ready-to-use while 20% is custom-built, the following terms apply:
      </p>
      <ul>
        <li>Ready-to-Use Portion (₹1.6 lakh): The Client is required to pay 100% of the cost for the ready-to-use portion (₹1.6 lakh), as 
            cancellation is not valid for this part.
        </li>
        <li>Custom Portion (₹40,000): For the custom portion, the Client will be obligated to pay for the work completed up to the point 
            of cancellation. For example, if 50% of the custom work is completed, the Client will pay ₹20,000 (50% of ₹40,000).
        </li>
      </ul>

      <p>No refunds will be issued for any completed or ready-to-use components, even if the Client decides to cancel the project. The Client 
         must pay the full amount for the ready-to-use portion and the corresponding amount for the custom work completed.
      </p>

      <p><strong>If Project Canceled by the Company:</strong></p>
      <ul>
        <li>If 100% of the project remains incomplete, the Company will refund the full payment made by the Customer, including the advance.
        </li>
        <li>If, for any reason, including but not limited to government guidelines for development, legal issues, or unavailability of company 
            resources, the Company is unable to complete the project, the Company will refund the Customer a portion of the payment based on 
            the percentage of the project that remains incomplete.
        </li>
        <li>Additionally, the Company will provide the Customer with the source code for the completed portion, enabling the Customer to 
            complete the project with another company or developer.
        </li>
      </ul>

      <p>This refund will only be applicable if the Company decides that it cannot complete the project, and the refund will be processed within 
         45-60 days of that decision.
      </p>

      <h2> Effective Date: </h2>
       <p><strong>Effective Date:</strong></p>
      <p>This Agreement shall become effective as of the date upon which both parties have executed this Agreement. 
         The Company’s obligations under this Agreement shall be deemed fulfilled upon the delivery of the website to 
         the Client, as per the specifications agreed upon.
      </p>
      <p>The Agreement may be terminated earlier by either party in accordance with the termination provisions specified 
         herein. In the event of early termination, the Client shall remain responsible for payment for all services rendered 
         up to the date of termination, and both parties shall settle any outstanding obligations as per the terms of this 
         Agreement.
      </p>

      {/* Project Related */}
      <p><strong>Project Related:</strong></p>
      <p>The Company agrees to develop and deliver a software project based on the demo link shared with and finalized 
         by the Client.
      </p>

      <p><strong>Note:</strong></p>
      <ul>
        <li>1.The Company agrees to develop the software in accordance with the features and 
functionality demonstrated in the demo version provided to the Client. The final project will 
be developed to reflect the exact features shown in the demo. Any functionality that is not 
working or incomplete in the demo will similarly not be functional in the final version of the 
project unless specifically addressed in the Agreement. The Company is not obligated to 
add, modify, or extend any features beyond what is presented in the demo unless 
otherwise agreed upon in writing by both parties.
        </li>
        <li>2. If any representative of the Company provides promises or assurances regarding the 
project that are not included in the official Agreement, it is the Client’s responsibility to 
raise these issues and seek clarification before signing the Agreement. Once the 
Agreement is signed, only the terms and conditions explicitly stated will be considered 
valid and enforceable. Any other discussions, informal commitments, or verbal 
understandings will not be recognized as legally binding unless incorporated into the 
Agreement text.
        </li>
        <li>- For any custom development requested by the Customer, additional time will be 
required based on the scope of work, which will be communicated in advance. 
        </li>
        <li>- This timeline is an estimate and may vary. If unforeseen issues arise, the timeline may 
be extended accordingly. The Company will inform the Customer of any such changes 
and provide updated estimates. By agreeing to this clause, the Customer 
acknowledges and accepts that the timeline may be adjusted as necessary.</li>
      </ul>

      {/* Post-Delivery Support */}
      <p><strong>Post-Delivery Support:</strong></p>
      <ul>
        <li> • Bug fixes and error resolution will be provided for the committed period post-delivery.</li>
        <li> • Additional services or feature requests will incur extra charges either just after or before delivery.</li>
      </ul>

      {/* Support Services */}
      <p><strong>Support Services:</strong></p>
      <ul>
        <li> • Support services for bug fixes and error resolution will be available during the Company’s official business hours:
          <ul>
            <li>Monday to Friday: 10:30 AM to 6:00 PM</li>
            <li>Saturday: 10:30 AM to 2:00 PM</li>
          </ul>
        </li>
        <li> • The Company will not provide support outside of these hours. Support requests made after-hours will be addressed 
            during the next official business period.
        </li>
        <li> • Support services will not be available on government holidays or during unforeseen circumstances affecting operations.
        </li>
      </ul>

      {/* Final Delivery */}
      <p><strong>Final Delivery:</strong></p>
      <p> • Upon completion of the development work, the Company will deliver the final product to the Client. The Company’s 
         obligations end with the delivery of the specified deliverables, and no further modifications or actions will be provided 
         unless agreed upon in a separate agreement.
      </p>

      {/* Custom Development */}
      <p><strong>Custom Development:</strong></p>
      <ul>
        <li>- In case the Customer requests any additional custom development during or after the 
project, the Company can either develop the custom part themselves or provide the 
Customer the ability to develop it themselves.
        </li>
        <li>- Custom development will incur additional charges and extend the timeline, as agreed upon by both parties.
        </li>
      </ul>

      {/* Data Backup Policy */}
      <p><strong>Data Backup Policy:</strong></p>
      <p>In the event of accidental data loss, the Company will provide data backup strictly in 
accordance with the hosting plan purchased by the Client. The Client is entitled to a 
backup for the duration specified in their chosen hosting plan. For example, if the Client’s 
hosting plan includes a 7-day backup, the Company will restore data from the last 7 days. 
If the plan includes a 1-day backup, the Client will receive a backup from the last 24 hours. 
The Company is not responsible for maintaining any backups. Furthermore, if any data 
loss occurs due to chance, human error, or unforeseen activities, the Company is not liable 
for such incidents. 
      </p>

      {/* Exclusions */}
      <p><strong>Exclusions:</strong></p>
      <p>• Ongoing maintenance beyond the support period.</p>
      <p>• Legal compliance verification and promotional activities. </p>

      <h2>Client Responsibilities:</h2>

      {/* Disclaimer Clause */}
      <p><strong>Disclaimer Clause:</strong></p>
      <p>The Company makes no representations or warranties regarding the legality of the Client’s use of the developed 
         software. It is the Client's responsibility to ensure that their activities comply with all relevant laws and regulations.
      </p>
      <p><strong>Licensing for Entertainment or Real Money-Based Use:</strong></p>
      <p>If the Client intends to use the game for entertainment purposes or for real money-based activities, it is the Client’s 
         responsibility to obtain the necessary licenses and permits in accordance with applicable laws. The Company will not 
         be responsible for verifying the legality of the Client’s operations. The Client must ensure full legal compliance 
         before offering the game to users.
      </p>

      {/* Mutual Acknowledgement */}
      <p><strong>Mutual Acknowledgement:</strong></p>
      <ul>
        <li>Both parties agree that the Company’s involvement ends with the delivery of the website as per the Client’s 
            specifications and does not extend to operation, promotion, or management post-delivery. However, the Company 
            will provide bug-fixing and error-resolution services for a specified support period after delivery.
        </li>
        <li>The Client acknowledges that any legal actions, disputes, or claims from the operation of the game website 
            are solely the Client’s responsibility. The Company is not liable for such disputes. The Client agrees to indemnify 
            and hold the Company harmless from all legal costs and damages incurred as a result of such disputes.
        </li>
        <li>The Company is not involved in any partnership or joint venture with the Client beyond providing development services.
        </li>
      </ul>

      {/* Cancellation by Client */}
      <p><strong>If Project Canceled by the Customer:</strong></p>
      <ul>
        <li><strong>Advance Payment:</strong> Any advance payment made by the Client is non-refundable. The advance is a 
            commitment towards development and will not be returned if the project is canceled.
        </li>
        <li><strong>Custom Project:</strong> If the Client cancels a custom project, the Client must pay for the portion of work 
            completed by the Company.
        </li>
        <li><strong>Ready-to-Use Project:</strong> For ready-to-use projects, cancellation is not valid. The Client must pay 
            the full project amount.
        </li>
        <li><strong>Hybrid Project:</strong> For hybrid projects, the Client will pay 100% for the ready-to-use portion and a 
            proportional amount for the custom portion completed.
        </li>
      </ul>

      {/* Cancellation by Company */}
      <p><strong>If Project Canceled by the Company:</strong></p>
      <ul>
        <li>If the Company is unable to complete the project, they will refund the Client for the incomplete portion and provide 
            the source code for the completed work.
        </li>
        <li>Refunds will be processed within 45-60 days if the Company cancels the project.
        </li>
      </ul>
      </div>

      {/* <div className="section">
        <h2>Applicable Laws and Regulations</h2>
        <p>
          The Client is responsible for ensuring compliance with all applicable laws, including 
          but not limited to:
        </p>
        <ul>
          <li>The Public Gambling Act, 1867</li>
          <li>The Rajasthan Public Gaming Ordinance, 1949</li>
          <li>The Information Technology (IT) Act, 2000</li>
          <li>RBI Guidelines for Online Payments</li>
          <li>International Licensing Requirements</li>
        </ul>

        <h2>Client’s Full Responsibility</h2>
        <p>
          The Client acknowledges and agrees that they are fully responsible for the legal 
          compliance, management, and operation of the game website once development 
          is complete. This includes all aspects related to regulatory compliance, user safety, 
          financial transactions, and dispute resolution with users or government authorities.
        </p>
      </div> */}
    </div>           </div>

            </div>

        </>
    )
}

export default RefundPolicy
