import React from "react";

const QuizIndustries = () => {
  return (
    <div className="m-0">
      <div className="dashboard-main-box">
        <h4 className="Key-feature-heading mb-5" style={{ fontSize: '40px', textAlign: "center" }}>Software Uses Industries</h4>
        <div className="dashboard-container">
          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/online-learning.webp" alt="Educational Institutions" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Educational Institutions
              </h4>
              <p>
                Educational Institutions extensively employ Quiz System Software
                to conduct quizzes, tests, and assessments for students across
                various subjects and grade levels.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/online-lesson.webp" alt="Online Learning Platforms" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Online Learning Platforms
              </h4>
              <p>
                Online Learning Platforms integrate Quiz System Software to
                facilitate online quizzes and assessments for distance learning
                programs, enhancing the interactive learning experience.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/coptraning.webp" alt="Corporate Training Programs t" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Corporate Training Programs t
              </h4>
              <p>
                Corporate Training Programs utilize Quiz System Software for
                employee training, compliance assessments, and skill development
                initiatives, ensuring effective knowledge transfer and
                retention.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/certification.webp" alt="Professional Certifications" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Professional Certifications
              </h4>
              <p>
                Utilized for conducting certification exams for various
                professional credentials.
              </p>
            </div>
          </div>
        </div>
        <div className="dashboard-container">
          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/banner.webp" alt="Events and Conferences" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Events and Conferences
              </h4>
              <p>
                Engage attendees with interactive quizzes during events and
                conferences.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/growth.webp" alt="Marketing and Engagement" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Marketing and Engagement
              </h4>
              <p>
                Marketing teams deploy quizzes for customer engagement and lead
                generation.
              </p>
            </div>
          </div>
          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/quizhealth.webp" alt="Healthcare Training" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Healthcare Training
              </h4>
              <p>
                Medical professionals use it for training and staying updated
                with medical knowledge.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/user.webp" alt="Personal Development" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Personal Development
              </h4>
              <p>
                Individuals utilize quizzes for self-assessment and skill
                enhancement, fostering personal growth and development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizIndustries;
