import React from 'react'

const FoodDelevryIndustries = () => {
    return (
        <div className="m-0">
            <div className="dashboard-main-box">
                <h4 className="mobile-develop-haeding Key-feature-heading mb-5" style={{ fontSize: '40px' }}>Application Uses Industries</h4>                <div className="dashboard-container">
                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/restaurant.webp" alt="Restaurants" />

                            <h4 className="software-feature-headings" >Restaurants</h4>
                            <p>
                                Food delivery apps help restaurants broaden their customer base by reaching a wider audience beyond their physical locations.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/supermarket.webp" alt="Hospitality" />

                            <h4 className="software-feature-headings" >Hospitality</h4>
                            <p>
                                Hotels partner with food delivery apps to offer guests convenient dining options without leaving their accommodations.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/briefcase.webp" alt="Corporate Catering" />

                            <h4 className="software-feature-headings" >Corporate Catering</h4>
                            <p>
                                Businesses use food delivery apps for hassle-free catering services for events and office gatherings.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/grocery-store.webp" alt="Grocery Stores" />

                            <h4 className="software-feature-headings" >Grocery Stores</h4>
                            <p>
                                Some grocery stores collaborate with food delivery apps for home delivery services, offering convenience to customers.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="dashboard-container">
                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/healthcare.webp" alt="Healthcare" />

                            <h4 className="software-feature-headings" >Healthcare</h4>
                            <p>
                                Hospitals utilize food delivery apps to provide patients with tailored meals during their stay.
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/education.webp" alt="Educational" />

                            <h4 className="software-feature-headings" >Educational</h4>
                            <p>
                                Institutions: Schools partner with food delivery apps to offer meal delivery services for students on campus.
                            </p>
                        </div>
                    </div>
                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/food-truck.webp" alt="Food Trucks" />

                            <h4 className="software-feature-headings" >Food Trucks</h4>
                            <p>
                                Food truck vendors expand their customer reach by partnering with food delivery apps.
                            </p>
                        </div>
                    </div>


                    <div className="dashboard-container-box dashboard-boxes">
                        <div className="key-feature-heading">
                            <img src="./images/food.webp" alt="Specialized Diets" />

                            <h4 className="software-feature-headings" >Specialized Diets</h4>
                            <p>
                                Food delivery apps offer options for specialized dietary preferences, ensuring inclusivity for all users.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoodDelevryIndustries
