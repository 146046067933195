import React from "react";

const UniversityKeyFeature = () => {
  return (
    <div style={{ marginBottom: "50px " }}>
      <div className="dashboard-main-box">
        <h4 className="Key-feature-heading mb-5" style={{ fontSize: '40px' }}>Key Feature</h4>
        <div className="dashboard-container">
          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/students.webp" alt="Student Management" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Student Management
              </h4>
              <p>
                Student Management handles student records, academic history,
                and personal details for streamlined administration.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/class.webp" alt="Course Organization" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Course Organization
              </h4>
              <p>
                Course Organization aids in syllabus creation, scheduling, and
                assignment management, enhancing teaching efficiency.
              </p>
            </div>
          </div>
          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/seo.webp" alt="Faculty Oversight" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Faculty Oversight
              </h4>
              <p>
                Faculty Oversight manages faculty recruitment, assignments, and
                evaluations for optimal staffing.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/add-friend.webp" alt=" Online Enrollment" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Online Enrollment
              </h4>
              <p>
                Online Enrollment simplifies course registration, schedule
                viewing, and fee processing for students.
              </p>
            </div>
          </div>
        </div>
        <div className="dashboard-container">
          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/feedback.webp" alt="Assessment and Grading" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Assessment and Grading
              </h4>
              <p>
                Assessment and Grading assists instructors in evaluating student
                performance and providing feedback effectively.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/assets.webp" alt="FiAdministrationnancial " />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                FiAdministrationnancial 
              </h4>
              <p>
                Financial Administration streamlines tuition payments,
                budgeting, and financial aid disbursement for transparency.
              </p>
            </div>
          </div>
          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/devashugahlibary.webp" alt="Library Operations" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Library Operations
              </h4>
              <p>
                Library Operations systemizes cataloging, circulation, and
                inventory management for improved resource accessibility.
              </p>
            </div>
          </div>

          <div className="dashboard-container-box dashboard-boxes">
            <div className="key-feature-heading">
              <img src="./images/conversation.webp" alt="Communication Tools" />

              <h4 className="software-feature-headings d-flex flex-column justify-center align-center">
                Communication Tools
              </h4>
              <p>
                Communication Tools facilitate collaboration and information
                sharing among students, faculty, and staff.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversityKeyFeature;
