import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BlogManagement = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState(null);
  const [editBlog, setEditBlog] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://maincompanybackend.metablocktechnologies.org/api/v1/mainartical');
      setBlogs(response.data.data);
    } catch (error) {
      console.error('Error fetching blogs:', error.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://maincompanybackend.metablocktechnologies.org/api/v1/blogcategory');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error.message);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleAddOrUpdateBlog = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category_id', category);
    if (image) {
      formData.append('image', image);
    }

    try {
      if (editBlog) {
        await axios.put(`https://maincompanybackend.metablocktechnologies.org/api/v1/mainartical/${editBlog._id}`, formData);
      } else {
        await axios.post('https://maincompanybackend.metablocktechnologies.org/api/v1/mainartical/', formData);
      }
      fetchBlogs();
      clearForm();
    } catch (error) {
      console.error('Error saving blog:', error.message);
    }
  };

  const handleEditBlog = (blog) => {
    setEditBlog(blog);
    setTitle(blog.title);
    setDescription(blog.description);
    setCategory(blog.category_id ? blog.category_id._id : '');
    setImage(null);
  };

  const handleDeleteBlog = async (id) => {
    try {
      await axios.delete(`https://maincompanybackend.metablocktechnologies.org/api/v1/mainartical/${id}`);
      fetchBlogs();
    } catch (error) {
      console.error('Error deleting blog:', error.message);
    }
  };

  const clearForm = () => {
    setTitle('');
    setDescription('');
    setCategory('');
    setImage(null);
    setEditBlog(null);
  };

  const filteredBlogs = selectedCategory
    ? blogs.filter((blog) => blog.category_id && blog.category_id._id === selectedCategory)
    : blogs;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-center text-2xl font-bold mb-8">Blog Management</h1>

      {/* Category Filter */}
      <div className="mb-8 text-center">
        <label className="font-bold text-gray-700 mr-4">Filter by Category:</label>
        <select
          className="p-2 border rounded text-black"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="" className="text-black">All Categories</option>
          {categories.map((cat) => (
            <option key={cat._id} value={cat._id} className="text-black">
              {cat.name}
            </option>
          ))}
        </select>
      </div>

      {/* Form Section */}
      <form onSubmit={handleAddOrUpdateBlog} className="w-full max-w-lg mx-auto p-4 bg-white shadow rounded mb-8">
        <h2 className="text-xl font-bold mb-4">{editBlog ? 'Edit Blog' : 'Add New Blog'}</h2>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Title</label>
          <input
            type="text"
            className="w-full p-2 border rounded text-black"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Description</label>
          <textarea
            className="w-full p-2 border rounded text-black"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Category</label>
          <select
            className="w-full p-2 border rounded text-black"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="" className="text-black">Select a category</option>
            {categories.map((cat) => (
              <option key={cat._id} value={cat._id} className="text-black">
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Image</label>
          <input
            type="file"
            className="w-full p-2 border rounded"
            onChange={handleImageChange}
          />
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {editBlog ? 'Update Blog' : 'Add Blog'}
        </button>
        {editBlog && (
          <button
            type="button"
            onClick={clearForm}
            className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel Edit
          </button>
        )}
      </form>

      {/* Blog List Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredBlogs?.map((blog) => (
          <div key={blog._id} className="bg-white shadow rounded overflow-hidden">
            <img src={`https://maincompanybackend.metablocktechnologies.org/mainArtical/images/${blog?.image}`} alt={blog.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h2 className="text-lg font-bold text-black text-center">{blog.title}</h2>
              <p className="text-gray-600">{blog.description}</p>
              <p className="text-gray-600"><strong>Category:</strong> {blog.category_id ? blog.category_id.name : 'No Category'}</p>
              <div className="mt-4 flex justify-between">
                <button
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-3 rounded"
                  onClick={() => handleEditBlog(blog)}
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
                  onClick={() => handleDeleteBlog(blog._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogManagement;
